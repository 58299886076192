:root {
    --bg-primary: #121212;
    --bg-secondary: #242424;
    --text-primary: #E0E0E0;
    --text-accent: #4CAF50;
    --text-secondary: #757575;
    --neon-green: #39FF14;
}

body {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    font-family: "Jost", sans-serif;
}

.team-heading1 {
    text-align: center;
    font-size: 2.5rem;
    margin: 2rem 0;
    /* color: var(--text-accent); */
}

.expert-grid1 {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
    padding: 2rem;
    width: 100%;
    justify-content: center;
    align-items: center;
    
    overflow-x: auto; /* Allow horizontal scrolling if necessary */
}

.expert-card1 {
    flex: 0 0 auto;
    background-color: var(--bg-secondary);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    width: 240px;
}

.expert-card1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
    /* background: var(--neon-green); */
    filter: blur(8px);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.expert-card1:hover::before {
    opacity: 1;
}

.expert-card1:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.expert-info1 {
    padding: 1rem;
    text-align: center;
}

.avatar-wrapper1 {
    width: 100%;
    height: 220px;
    overflow: hidden;
}

.expert-avatar1 {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.expert-card1:hover .expert-avatar1 {
    transform: scale(1.05);
}

.expert-name1 {
    color: var(--text-accent);
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 0.75rem;
    margin-bottom: 0.25rem;
}

.expert-role1 {
    color: var(--text-secondary);
    font-size: 0.9rem;
    margin-bottom: 0.5rem;
}
.linkedin{
    color: white;
}