:root {
  --neon-green: #39FF14;
}

body, html {
  margin: 0;
  padding: 0;
  font-family: "Jost", sans-serif;
  color: white;
  background-color: #001a1a;
  overflow-x: hidden;
}

.root {
  background-color: #00190F;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

.container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.mid {
  /* border: 1px solid white; */
  height: 40vh;
  width: 90%;
  position: relative;
  left: 5%;
  top: 100px;
}

.mid .img1 {
  position: relative;
  scale: 0.7;
  top: -200px;
}

header {
  /* background: rgba(255, 255, 255, 0.245); */
  /* backdrop-filter: blur(50px); */
  /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
  color: white;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
}

nav {
  
  flex-direction: column;
  position: absolute;
  text-align: center;
  width: 20%;
  height: 90vh;
  top: 90px;
  left: 100%;
  right: 0;
  font-family: "Jost", sans-serif;
  backdrop-filter: blur(10px);
  padding: 1rem;
  transition: all 1s;
  display: flex;
  justify-content: space-around;
  background: #051d14b0; 
  backdrop-filter: blur(30px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);

}

nav.active {
  left: 80%;
  
  opacity: 30;
}
nav a, .register-btn {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: larger;
  transition: all 0.3s;
  border-radius: 20px;
}

nav a:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.register-btn {
  background-color: rgba(76, 175, 80, 0.6);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-btn:hover {
  background-color: rgba(76, 175, 80, 0.8);
}

.menu-icon {
  display: flex;
  font-size: 2rem;
  cursor: pointer;
}

@media (max-width: 768px) {
  .menu-icon {
      display: block;
  }

  nav {
      display: none;
      flex-direction: column;
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      transition: all 1s;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(10px);
      padding: 1rem;
  }

  nav.active {
      display: flex;
      left: 30%;
      width: 70%;
  }}
.hero {
  text-align: center;
  padding: 100px 0;
  position: relative;
}
.subtle {
  font-size: 78px;
  text-transform: uppercase;
  color: white;
  letter-spacing: 2px;
  margin-bottom: 20px;
  animation: float 3s ease-in-out infinite;
}

.subtitle {
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 20px;
  animation: float 3s ease-in-out infinite;
}

h1 {
  font-size: 72px;
  margin-bottom: 20px;
  text-transform: uppercase;
  color: var(--neon-green);
  text-shadow: 0 0 20px var(--neon-green);
  animation: pulse 2s ease-in-out infinite;
}

.description {
  font-size: 16px;
  max-width: 600px;
  margin: 0 auto 40px;
}

.cta-button {
  background-color: var(--neon-green);
  color: #001a1a;
  padding: 15px 30px;
  border: none;
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
  text-transform: uppercase;
  transition: all 0.3s ease;
  box-shadow: 0 0 10px var(--neon-green);
}

.cta-button:hover {
  background-color: #001a1a;
  color: var(--neon-green);
  box-shadow: 0 0 20px var(--neon-green);
}

.floating-element {
  position: absolute;
  background-color: rgba(57, 255, 20, 0.6);
  border-radius: 50%;
  pointer-events: none;
  box-shadow: 0 0 20px rgba(57, 255, 20, 0.8);
}

@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-10px); }
}

@keyframes pulse {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.8; }
}

.countdown-container {
  color: #fff;
  padding: 40px;
  text-align: center;
  font-family: "Jost", sans-serif;
  border-radius: 10px;
  width: 1000px;
  margin: 0 auto;
}

.timer-container {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.timer-segment {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin: 20px 0;
}

.timer-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(145deg, #2e2e2e, #1c1c1c);
  box-shadow: 10px 10px 20px #0a0a0a, -10px -10px 20px #262626;
  position: relative;
  overflow: hidden;  /* Add this to clip the pseudo-element */
}

.timer-circle::before {
  content: '';
  position: absolute;
  top: -2px;  /* Adjust as needed */
  left: -2px;  /* Adjust as needed */
  right: -2px;  /* Adjust as needed */
  bottom: -2px;  /* Adjust as needed */
  border: 4px solid transparent;
  border-radius: 50%;
}

.timer-circle::before {
  border-color: #00ff00;
  animation: neonTrace1 1.5s linear infinite ;
}

@keyframes neonTrace1 {
  0% {
      clip-path: polygon(0% 0%, 5% 0%, 5% 100%, 0% 100%);
  }
  25% {
      clip-path: polygon(0% 0%, 100% 0%, 100% 5%, 0% 5%);
  }
  50% {
      clip-path: polygon(95% 0%, 100% 0%, 100% 100%, 95% 100%);
  }
  75% {
      clip-path: polygon(0% 95%, 100% 95%, 100% 100%, 0% 100%);
  }
  100% {
      clip-path: polygon(0% 0%, 5% 0%, 5% 100%, 0% 100%);
  }
}
.timer-number {
  font-size: 2.5em;
  font-weight: bold;
  color: #39ff14;
  z-index: 1;
  transition: transform 0.5s ease;
}

.timer-label {
  font-size: 2em;
  color: #888;
  text-transform: uppercase;
  margin-top: 20px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sweep {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@media (max-width: 786px) {
  .countdown-container{
    /* border: 1px solid white; */
    width: 100%;
  }
}






.footer-logo{
  background-color: white;
  width: max-content;
  border-radius: 10px;
  height: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
}