:root {
    --bg-primary: #001A1A;
    --bg-secondary: #002626;
    --text-primary: #E0E0E0;
    --text-accent: #4CAF50;
    --text-secondary: #A0A0A0;
    --border-color: rgba(76, 175, 80, 0.2);
}

body {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    font-family: 'Arial', sans-serif;
}

h1 {
    text-align: center;
    color: #39FF14;
    font-size: 2.2rem;
    margin-bottom: 2.5rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.expert-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 2rem;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.expert-card {
    background-color: var(--bg-secondary);
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    height: 110%;
}

.expert-card:hover {
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    transform: translateY(-5px);
}

.avatar-wrapper {
    width: 100%;
    height: 110%;
    padding-top: 75%; /* 4:3 Aspect Ratio */
    position: relative;
    overflow: hidden;
}

.expert-avatar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
    object-fit: cover;
}

.expert-card:hover .expert-avatar {
    transform: scale(1.05);
}

.expert-info {
    padding: 1.5rem;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.expert-name {
    color: var(--text-accent);
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0 0 0.5rem;
    text-align: center;
}

.expert-role {
    color: var(--text-secondary);
    font-size: 0.9rem;
    text-align: center;
    margin: 0;
    line-height: 1.4;
}

@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

.expert-card {
    animation: fadeIn 0.4s ease-out forwards;
    opacity: 0;
}

.expert-card:nth-child(1) { animation-delay: 0.1s; }
.expert-card:nth-child(2) { animation-delay: 0.2s; }
.expert-card:nth-child(3) { animation-delay: 0.3s; }

@media (max-width: 768px) {
    .expert-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    }
}