.theme-cards-title {
    color: #ffffff;
    text-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
}

.theme-cards-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 2rem;
}

.theme-card {
    border-radius: 15px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    padding: 1.5rem;
    margin: 1rem;
    width: 380px;
    height: 380px;
    display: flex;
    flex-direction: column;
    font-family: "Jost", sans-serif;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    background-color: rgba(0, 255, 0, 0.05);
    border: 1px solid rgba(0, 255, 0, 0.1);
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
    opacity: 0;
    animation: fadeInUp 0.5s cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.theme-card:hover {
    transform: translateY(-10px) scale(1.02);
    
}

.theme-card::before,
.theme-card::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 15px;
    pointer-events: none;
}

.theme-card::before {
    border: 2px solid transparent;
    transition: border-color 0.3s ease-in-out;
}

.theme-card:hover::before {
    border-color: #00ff00;
    animation: neonTrace 1.5s linear infinite;
}

.theme-card::after {
    background: radial-gradient(circle at 50% 50%, rgba(0, 255, 0, 0.1), transparent 50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
}

.theme-card:hover::after {
    opacity: 1;
}

.theme-card-content {
    z-index: 1;
    transition: transform 0.3s ease-in-out;
}

.theme-card:hover .theme-card-content {
    transform: translateY(-5px);
}

.theme-card-title {
    color: #ffffff;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-shadow: 0 0 5px rgba(0, 255, 0, 0.5);
    font-family: "Jost", sans-serif;
}

.theme-card-description {
    color: #e0e0e0;
    font-size: 1.25rem;
    line-height: 1.6;
    text-align: justify;
    font-family: "Jost", sans-serif;
}

.theme-card-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    transition: transform 0.3s ease-in-out;
}

.theme-card:hover .theme-card-icon {
    transform: scale(1.2);
}

.theme-card-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /* background: linear-gradient(45deg, rgba(0, 255, 0, 0.1), rgba(0, 255, 0, 0.3)); */
    opacity: 0;
    transition: opacity 0.5s ease;
}

.theme-card:hover .theme-card-background {
    opacity: 6;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes neonTrace {
    0% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
    }
    25% {
        clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    }
    50% {
        clip-path: polygon(100% 0%, 100% 0%, 100% 100%, 100% 100%);
    }
    75% {
        clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    }
    100% {
        clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
    }
}

.theme-cards-container .theme-card:nth-child(1) { animation-delay: 0.7s; }
.theme-cards-container .theme-card:nth-child(2) { animation-delay: 0.8s; }
.theme-cards-container .theme-card:nth-child(3) { animation-delay: 0.9s; }
.theme-cards-container .theme-card:nth-child(4) { animation-delay: 1.0s; }
.theme-cards-container .theme-card:nth-child(5) { animation-delay: 1.1s; }
.theme-cards-container .theme-card:nth-child(6) { animation-delay: 1.2s; }
.theme-cards-container .theme-card:nth-child(7) { animation-delay: 1.3s; }
.theme-cards-container .theme-card:nth-child(8) { animation-delay: 1.4s; }