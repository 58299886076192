.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
  /* background-color: #001a00; Dark green background */
}

.particles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.particle {
  position: absolute;
  animation: float 15s ease-in-out infinite;
}

.particle svg {
  filter: drop-shadow(0 0 5px #00ff00);
}

@keyframes float {
  0%, 100% {
    transform: translateY(0) translateX(0) rotate(0deg);
  }
  25% {
    transform: translateY(-20px) translateX(10px) rotate(5deg);
  }
  50% {
    transform: translateY(-40px) translateX(-10px) rotate(-5deg);
  }
  75% {
    transform: translateY(-20px) translateX(5px) rotate(3deg);
  }
}