@keyframes glow {
  0% {
    filter: drop-shadow(0 0 0 rgba(255, 255, 255, 0.5));
  }
  50% {
    filter: drop-shadow(0 0 30px rgba(255, 255, 255, 1));
  }
  100% {
    filter: drop-shadow(0 0 0 rgba(255, 255, 255, 0.5));
  }
}
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

.root {
  height: 110vh;
  overflow: hidden;
  background-image: url(images/Untitled\ design\ \(21\).png);
  background-size: cover;
  background-position: center ; 
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

.hero {
  display: flex;
  width: 100%;
  justify-content: space-between;
  justify-content: center;
  align-items: center;
  height: 35vh;
  background-color: transparent;
  position: relative;
}

.neon-heading {
  font-family: 'Montserrat', sans-serif;
  font-size: 6rem;
  font-weight: 700;
  color: #ffffff; 
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
  white-space: nowrap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow:
    0 0 7px #66ff66,
    0 0 10px #66ff66,
    0 0 21px #66ff66,
    0 0 42px #66ff66,
    0 0 82px #66ff66,
    0 0 92px #66ff66,
    0 0 102px #66ff66,
    0 0 151px #66ff66;
  animation: fadeIn 2s ease-in-out, pulsate 1.5s infinite alternate;
  margin-bottom: 10px; /* Reducing the gap */
}

.neon-tagline {
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  color: #ffffff; 
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  position: relative;
  white-space: nowrap;
  text-rendering: geometricPrecision;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-shadow:
    0 0 7px #66ff66,
    0 0 10px #66ff66,
    0 0 21px #66ff66,
    0 0 42px #66ff66,
    0 0 82px #66ff66,
    0 0 92px #66ff66,
    0 0 102px #66ff66,
    0 0 151px #66ff66;
  animation: fadeIn 2s ease-in-out, pulsate 1.5s infinite alternate;
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes pulsate {
  100% {
      text-shadow:
        0 0 4px #66ff66,
        0 0 11px #66ff66,
        0 0 19px #66ff66,
        0 0 40px #66ff66,
        0 0 80px #66ff66,
        0 0 90px #66ff66,
        0 0 100px #66ff66,
        0 0 150px #66ff66;
  }
  0% {
      text-shadow:
        0 0 2px #66ff66,
        0 0 4px #66ff66,
        0 0 6px #66ff66,
        0 0 10px #66ff66,
        0 0 45px #66ff66,
        0 0 55px #66ff66,
        0 0 70px #66ff66,
        0 0 80px #66ff66;
  }
}
.logo11{
  position: absolute;
  /* right: 100px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  width: 170px;
  gap: 10px;
  top: 130px;
  background-color: white;
  border-radius: 5px;
  p{
    margin: 0%;
    color: black;
    font-weight: bold;
  }
  h5{
    color: black;
    margin: 0%;
  }

}
.logo22{
  position: absolute;
  left: 100px;
  border: 1px solid white;
  width: 170px;
  top: 80px;
  background-color: white;
  border-radius: 5px;
  p{
    margin: 0%;
    color: black;
    font-weight: 600;

  }
}

.logo-img{
  position: relative;
  width: 200px;
}

.logo1 {
  width: 80%;
  border-radius: 10px;
  

}

.logos {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;
  border: 1px solid white;
}

.logo2 {
  width: 100%;
  border-radius: 5px;
}

.content2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  margin-bottom: 30px;
  width: 100%;
  top: 60vh;
  left: 0%;
  gap: 10px;
}

.content2 p {
  font-size: medium;
  color: white;
  font-family: Arial,  sans-serif;
  font-weight: bolder;
  font-size:20px ;
  position: absolute;
  top: 240px;
}

 
.lllll{
  display: flex;
  gap: 50px;
  
}
.main{
  display: flex;

}


.neonb {
  position: absolute;
  padding: 10px 20px;
  font-size: 20px;
  border-radius: 10px;
  top: 290px;
  color: #0f0;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  letter-spacing: 4px;
  border: 2px solid #0f0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.neonb::before,
.neonb::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #0f0;
  transition: all 0.3s ease;
  z-index: -1;
}

.neonb::before {
  left: -100%;
  top: 0;
}

.neonb::after {
  right: -100%;
  bottom: 0;
}

.neonb:hover {
  color: #ffff;
  background-color: #0f0;
}

.neonb:hover::before {
  left: 0;
}

.neonb:hover::after {
  right: 0;
}

.neonb:hover {
  box-shadow: 0 0 30px 5px #0f0;
}


@media (max-width: 786px) {
  .logo22{
    position: absolute;
    top: 38rem;
    width:150px ;
    z-index: 10000;
    left: 10px;
    scale: 1.2;
    
  }
  .neon-heading{
    font-size: 3.5rem;
  }
  .logo-img{
    width: 100px;
  }
  .logo11{
    right: 10px;
    scale: 0.8;
  }
  .dates{
    margin-bottom: 20px;
  }
}
.dates{
  font-family: "Jost", sans-serif;
}