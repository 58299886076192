.marquee {
    background-color: #f0f0f0;
    padding: 20px 0;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
    width: 100%;
    margin-bottom: 30px;
    left: 0;
    background: transparent;
  }
  
  .marquee-content {
    display: inline-block;
    animation: marquee 100s linear infinite;
  }
  
  .marquee-content img {
    width: 350px;
    height: 200px;
    object-fit: cover;
    margin: 0 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  .h2{
    font-style: oblique;
  }