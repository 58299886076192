:root {
    --bg-primary: #001a1a;
    --text-primary: #e0e0e0;
    --accent-color: #4CAF50;
    --content-bg: rgba(0, 38, 38, 0.6);
}

body {
    font-family: "Jost", sans-serif;

}

.faq {
    background-color: var(--bg-primary);
    color: var(--text-primary);
    padding: 4rem 2rem;
}

.faq h2 {
    text-align: center;
    color: var(--accent-color);
    font-size: 2.7rem;
    margin-bottom: 3rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: "Jost", sans-serif;

    text-shadow: 0 0 10px rgba(76, 175, 80, 0.5);
}

.accordion {
    max-width: 900px;
    margin: 0 auto;
}

.accordion-item {
    margin-bottom: 1rem;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.accordion-toggle {
    width: 100%;
    background-color: rgba(0, 38, 38, 0.8);
    color: var(--accent-color);
    padding: 1rem;
    border: none;
    text-align: left;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
}

.accordion-toggle:hover {
    background-color: rgba(0, 38, 38, 1);
}

.accordion-toggle::after {
    content: '\002B';
    float: right;
    font-weight: bold;
}

.accordion-toggle.active::after {
    content: '\2212';
}

.accordion-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
    background-color: var(--content-bg);
}

.accordion-content p {
    padding: 1rem;
    margin: 0;
    word-spacing: 4px;
    line-height: 20px;
}
